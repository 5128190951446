import { defineStore } from 'pinia'

import { SSRService } from '@obr-core/services/SSRService'
import { EventService } from '@obr-core/services/api'
import { initialState } from './state'
import { raceStoreService } from '@obr-core/services/store/RaceStoreService'

const eventService = EventService.getInstance()

// used for return correct events and mantaince prefetch data (when change multiple time data events)
let eventsLastFilter
export const useEvents = defineStore('obr-store/events', {
    state: initialState,
    actions: {
        async onEventCardLoadEvent(eventId: string) {
            const eventSSR = SSRService.getInstance().getEventCardOnce()

            if (eventSSR) {
                this.onSetEventCardEvent(eventSSR)
                return this.eventsCache[eventId]
            }

            const event = await eventService.getEventById(eventId)
            if (event) {
                this.onSetEventCardEvent(event)
            }

            return this.eventsCache[eventId]
        },
        async onEventCardLoadH2H(eventId: string) {
            const eventSSR = SSRService.getInstance().getH2HOnce()

            if (eventSSR) {
                this.onSetEventCardEvent(eventSSR)
                return this.eventsCache[eventId]
            }

            const event = await eventService.getH2HEventById(eventId)
            if (event) {
                this.onSetEventCardEvent(event)
            }

            return this.eventsCache[eventId]
        },
        onEventCardLoadEventSpecial(eventId: string) {
            const eventSSR = SSRService.getInstance().getSpecialOnce()

            if (eventSSR) {
                this.onSetSpecialsInStore([eventSSR])
                return Promise.resolve(this.eventsCache[eventId])
            }

            const eventPromise: Promise<OBR.Events.Event> = eventService
                .getSpecialEventById(eventId)
                .then((event) => {
                    if (event) {
                        this.onSetSpecialsInStore([event])
                    }

                    return this.eventsCache[eventId]
                })

            return this.eventsCache[eventId]
                ? Promise.resolve(this.eventsCache[eventId])
                : eventPromise
        },
        async onEventCardLoadEventSpecials(): Promise<OBR.Events.Event[]> {
            const eventsSSR = SSRService.getInstance().getSpecialsOnce()

            if (eventsSSR) {
                this.onSetSpecialsInStore(eventsSSR)
                return Promise.resolve(eventsSSR)
            }
            return eventService.getSpecials().then((specials) => {
                if (!specials) return []
                this.onSetSpecialsInStore(specials)
                return specials || []
            })
        },
        onSetSpecialsInStore(specials: OBR.Events.Event[]) {
            specials.forEach((special) => {
                this.eventsCache[special.id] = { ...special }
                special.races.forEach((specialRace) => {
                    raceStoreService.addSpecialRaceToCache(specialRace)
                })
            })
        },
        async onEventBrowserLoadEvents(date: string) {
            eventsLastFilter = date
            this.eventBrowserEvents = {}
            let events: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserMeetingsOnce() || []

            if (!events.length) {
                events = await eventService.getAllEvents({
                    date,
                })
            }

            if (eventsLastFilter === date) {
                events.forEach((event) => {
                    this.eventBrowserEvents[event.id] = event
                })
            }
        },
        async onEventBrowserLoadAntepostEvents() {
            let events: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserAntepostOnce() || []

            if (!events.length) {
                events = await eventService.getAllEvents({
                    antepost: '1',
                })
            }

            events.forEach((event) => {
                this.antepostEvents[event.id] = event
            })
        },
        async onEventBrowserLoadVirtualEvents() {
            let events: OBR.Events.Event[] =
                SSRService.getInstance().getEventBrowserVirtualsOnce() || []
            if (!events.length) {
                events = await eventService.getAllEvents({
                    virtual: true,
                })
            }

            events.forEach((event) => {
                this.antepostEvents[event.id] = event
            })
        },
        async onEventBrowserUpdateAntepostBySocket(
            updateEvents: OBR.Common.Object<OBR.Events.Event> = {}
        ) {
            Object.keys(updateEvents).forEach((idEvent: string) => {
                if (this.antepostEvents[idEvent]) {
                    this.antepostEvents[idEvent] = Object.assign(
                        this.antepostEvents[idEvent],
                        updateEvents[idEvent]
                    )
                }
            })
        },
        /**
         * map and update events
         */
        async onEventBrowserUpdateEventsBySocket(
            updateEvents: OBR.Common.Object<OBR.Events.Event> = {}
        ) {
            Object.keys(updateEvents).forEach((idEvent: string) => {
                if (this.eventBrowserEvents[idEvent]) {
                    this.eventBrowserEvents[idEvent] = Object.assign(
                        this.eventBrowserEvents[idEvent],
                        updateEvents[idEvent]
                    )
                }
            })
        },
        /**
         * map and update virtual events
         */
        async onEventBrowserUpdateVirtualEventsBySocket(
            updateEvents: OBR.Common.Object<OBR.Events.Event> = {}
        ) {
            Object.keys(updateEvents).forEach((idEvent: string) => {
                if (this.virtualEvents[idEvent]) {
                    this.virtualEvents[idEvent] = Object.assign(
                        this.virtualEvents[idEvent],
                        updateEvents[idEvent]
                    )
                }
            })
        },
        /**
         * update races inside event cached and update isomorphic
         */
        onEventUpdateRacesBySocket({
            eventID,
            races,
        }: {
            eventID: string
            races: OBR.Events.SocketEventCardRaceNormalized[]
        }) {
            if (this.eventsCache[eventID] && this.eventsCache[eventID].races) {
                this.eventsCache[eventID].races = this.eventsCache[
                    eventID
                ].races.map((cacheRace) => {
                    const raceUpdate = races.find(
                        (race) => race.id === cacheRace.id
                    )
                    if (raceUpdate) {
                        if (raceUpdate.runners) {
                            raceUpdate.runners = cacheRace.runners.map(
                                (cacheRunner) => {
                                    const runnerUpdate =
                                        raceUpdate.runners?.find(
                                            (updateRunner) =>
                                                updateRunner.id ===
                                                cacheRunner.id
                                        )

                                    if (runnerUpdate) {
                                        return Object.assign(
                                            cacheRunner,
                                            runnerUpdate
                                        )
                                    }
                                    return cacheRunner
                                }
                            )
                        }
                        return Object.assign(cacheRace, raceUpdate)
                    }
                    return cacheRace
                })
            }
        },
        /**
         * load pickbets by eventId and set cache
         */
        async loadPickbetsByEventIdAndSetCache(
            eventId: string
        ): Promise<OBR.Events.Pickbet[]> {
            const pickbets: OBR.Events.Pickbet[] =
                await eventService.getPickbets(eventId)

            const hasCrossEvents = pickbets.some((pick) => !pick.single_event)

            if (hasCrossEvents) {
                const picks = pickbets.filter((pick) => !pick.single_event)

                for await (const pick of picks) {
                    await this.loadCrossEventsAndSetCache(pick, eventId)
                }
            }

            return pickbets.map((pickbet) => {
                this.setPickbetCache(pickbet)

                return this.pickbetsCache[pickbet.id]
            })
        },
        /**
         * Load cross events and set cache
         */
        async loadCrossEventsAndSetCache(
            pickbet: OBR.Events.Pickbet,
            eventId: string
        ): Promise<void> {
            const crossEventsIds = new Set<string>()

            pickbet.races.forEach((race) => {
                if (String(race.id_event) !== eventId)
                    crossEventsIds.add(race.id_event)
            })

            const crossEvents = await Promise.all(
                [...crossEventsIds].map((id) => eventService.getEventById(id))
            )

            crossEvents.forEach((event) => {
                if (!event) return
                this.onSetEventCardEvent(event)
            })
        },
        /**
         * load pickbets by raceId
         */
        async loadPickbetsByRaceId(
            raceId: string
        ): Promise<OBR.Events.Pickbet[]> {
            const pickbets: OBR.Events.Pickbet[] =
                await eventService.getPickbetsByRaceId(raceId)

            return pickbets.map((pickbet) => {
                this.setPickbetCache(pickbet)

                return this.pickbetsCache[pickbet.id]
            })
        },
        /**
         * Load pickbets by pickbetId and save them to cache
         */
        async onEventCardLoadPickbetById(
            pickbetId: string,
            eventID?: string
        ): Promise<OBR.Events.Pickbet | null> {
            const pickbet: OBR.Events.Pickbet | null =
                await eventService.getPickbet(pickbetId)

            if (pickbet) {
                this.setPickbetCache(pickbet)

                if (!pickbet.single_event) {
                    await this.loadCrossEventsAndSetCache(
                        pickbet,
                        eventID as string
                    )
                }

                return this.pickbetsCache[pickbet.id] as OBR.Events.Pickbet
            }

            return null
        },
        onSetEventCardEvent(event: OBR.Events.Event) {
            this.eventsCache[event.id] = {
                ...this.eventsCache[event.id],
                ...event,
            }

            event.races.forEach((race) => {
                raceStoreService.addRaceToStore(
                    race,
                    !!raceStoreService.raceByCache(race.id)
                )
            })
        },

        onSetPickbetsView(view?: string) {
            this.selectedPickbetView = view
        },

        /**
         * Set pickbet cache
         * @param {OBR.Events.Pickbet} pickbet
         */
        setPickbetCache(pickbet: OBR.Events.Pickbet) {
            this.pickbetsCache[pickbet.id] = pickbet
        },
    },
})
